// Lib
import { ThemeFile } from 'lib/context/ThemeContext';
import classNames from 'classnames';
import { ComponentBackgroundVariants } from 'src/helpers/Component/Component';

const getRBAQuoteClass = (style: ComponentBackgroundVariants): string => {
  const cssClass = 'absolute hidden lg:inline lg:pt-m w-[400px] h-[160px]';

  switch (style) {
    case 'black':
      return classNames(cssClass, 'opacity-[.15] fill-white text-white');
    case 'white':
      return classNames(cssClass, 'opacity-[.05] fill-black text-black');
    case 'gray':
      return classNames(cssClass, 'opacity-[.05] fill-black text-black');
    default:
      break;
  }
  return cssClass;
};

export const CarouselDynamicReviewsTheme = (style: ComponentBackgroundVariants): ThemeFile => {
  return {
    aw: {
      classes: {},
    },
    rba: {
      classes: {
        sectionWrapper: `my-8 lg:mx-auto md:max-w-screen-lg theme-white px-m`,
        headlineClass: `text-theme-text pb-s text-sm-xxl md:text-l font-extra-light font-sans`,
        buttonClass: {
          wrapper: `justify-center mt-m`,
          cta1Classes: `font-bold`,
        },
        quoteClass: getRBAQuoteClass(style),
        contentClasses:
          {
            copyContainerClass: `lg:px-xxl py-m mx-m flex flex-col`,
            ratingsIconsList: `mt-l lg:px-xxl flex`,
            body: `font-sans text-theme-text text-body lg:text-s font-extra-light lg:px-xxl py-m min-h-[200px]`,
            additionalInfo: `lg:px-xxl mb-m`,
            reviewerName: `text-theme-text text-body font-bold`,
            reviewerDetails: `text-theme-text text-body font-extra-light font-sans`,
          } || '',
      },
    },
  };
};
