/* eslint-disable prettier/prettier */

import { ReviewDetails, ReviewFiltersRequest, ReviewSummary } from './types';
import { Foundation } from '../../.generated/Foundation.EnterpriseWeb.model';
import { fetchInternalAPIWithQueryString } from 'lib/utils/api-request-utils';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

const apiUrl =
typeof window === 'undefined'
? process.env.EW_ENTERPRISE_WEB_APIv2_URL
: '';

export async function getReviewsDetails(filters: ReviewFiltersRequest): Promise<ReviewDetails[]> {

  if(!filters){
    return [];
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  };

  const response = await fetchInternalAPIWithQueryString(
    `${apiUrl}/api/rba/reviews/get-review-details/`,
    requestOptions
  )
  const responseData: ReviewDetails[]= await response?.json();

  return responseData;
}

export async function getReviewsSummary(filters: ReviewFiltersRequest): Promise<ReviewSummary | null>  {
  if(!filters){
    return null;
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  };

  const response = await fetchInternalAPIWithQueryString(
    `${apiUrl}/api/rba/reviews/get-reviews-summary/`,
    requestOptions
  )
  const responseData: ReviewSummary = await response?.json();

  return responseData;
}

export function parseReviewFilterData(review: Foundation.EnterpriseWeb.RenewalByAndersen.FieldSets.Reviews.ReviewFilters) {

  const parsedFilters: ReviewFiltersRequest = {
      productId: (review.fields?.product?.fields?.reviewModCategory as Field<string>)?.value?.replace(/\s/g, ''),
      affiliateId: (review.fields?.affiliate?.fields?.affiliateId as Field<string>)?.value,
      minimumRating: review.fields?.minimumRating?.name,
      containingText: review.fields?.reviewsKeyword?.value
    }

  return parsedFilters;
}
